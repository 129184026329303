import React from "react"
import { css } from "@emotion/react"
import { StaticImage } from "gatsby-plugin-image"

const Showcars = ({ theme }) => {
  const imageContainer = css`
    width: 100%;
  `
  return (
    <section
      css={[
        css`
          background: ${theme};
        `,
      ]}
    >
      <div css={[imageContainer, css``]}>
        <StaticImage
          src="../../../static/img/mbagavatar.png"
          alt="EXPO2023 speaker of STARTUP AUTOBAHN powered by Plug and Play"
          quality="100"
          placeholder="tracedSVG"
          // css={css`
          //   min-width: 1600px;
          // `}
        />
      </div>
      <div css={[imageContainer, css``]}>
        <StaticImage
          src="../../../static/img/porsche357.png"
          alt="EXPO2023 speaker of STARTUP AUTOBAHN powered by Plug and Play"
          quality="100"
          placeholder="tracedSVG"
          // css={css`
          //   min-width: 1600px;
          // `}
        />
      </div>
    </section>
  )
}

export default Showcars
