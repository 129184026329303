import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Marquee from "react-marquee-slider"
import { css } from "@emotion/react"
import PartnerLogo from "../main/logo-partner"
import PartnerGroups from "../defaults/filters/partnerGroups"
import { color } from "../defaults/styles/theme"
import { wrapper } from "../defaults/styles/elements"

const PartnerMarquee = () => {
  const data = useStaticQuery(graphql`
    {
      allAirtable(
        filter: { data: { active: { eq: true } }, table: { eq: "partners" } }
        sort: { fields: [data___start_date, data___name], order: ASC }
      ) {
        group(field: data___type) {
          edges {
            node {
              data {
                name
                start_date
              }
              recordId
            }
          }
          fieldValue
        }
      }
    }
  `)

  return (
    <section
      css={css`
        background: ${color.main_light};
        padding-top: 7rem;
      `}
    >
      <div
        css={[
          wrapper,
          css`
            position: relative;
            &:before,
            &:after {
              content: "";
              position: absolute;
              top: 0;
              height: 100%;
              width: 10%;
              max-width: 15rem;
              z-index: 1;
            }
            &:before {
              left: 0;
              background: linear-gradient(
                to left,
                hsla(240, 20%, 98%, 0) 0%,
                hsla(240, 20%, 98%, 0.013) 8.1%,
                hsla(240, 20%, 98%, 0.049) 15.6%,
                hsla(240, 20%, 98%, 0.104) 22.6%,
                hsla(240, 20%, 98%, 0.175) 29.2%,
                hsla(240, 20%, 98%, 0.259) 35.5%,
                hsla(240, 20%, 98%, 0.352) 41.5%,
                hsla(240, 20%, 98%, 0.45) 47.4%,
                hsla(240, 20%, 98%, 0.55) 53.3%,
                hsla(240, 20%, 98%, 0.648) 59.2%,
                hsla(240, 20%, 98%, 0.741) 65.2%,
                hsla(240, 20%, 98%, 0.825) 71.4%,
                hsla(240, 20%, 98%, 0.896) 77.9%,
                hsla(240, 20%, 98%, 0.951) 84.8%,
                hsla(240, 20%, 98%, 0.987) 92.1%,
                hsl(240, 20%, 98%, 0) 100%
              );
            }
            &:after {
              right: 0;
              background: linear-gradient(
                to right,
                hsla(240, 20%, 98%, 0) 0%,
                hsla(240, 20%, 98%, 0.013) 8.1%,
                hsla(240, 20%, 98%, 0.049) 15.6%,
                hsla(240, 20%, 98%, 0.104) 22.6%,
                hsla(240, 20%, 98%, 0.175) 29.2%,
                hsla(240, 20%, 98%, 0.259) 35.5%,
                hsla(240, 20%, 98%, 0.352) 41.5%,
                hsla(240, 20%, 98%, 0.45) 47.4%,
                hsla(240, 20%, 98%, 0.55) 53.3%,
                hsla(240, 20%, 98%, 0.648) 59.2%,
                hsla(240, 20%, 98%, 0.741) 65.2%,
                hsla(240, 20%, 98%, 0.825) 71.4%,
                hsla(240, 20%, 98%, 0.896) 77.9%,
                hsla(240, 20%, 98%, 0.951) 84.8%,
                hsla(240, 20%, 98%, 0.987) 92.1%,
                hsl(240, 20%, 98%, 0) 100%
              );
            }
          `,
        ]}
      >
        <Marquee velocity={10}>
          {PartnerGroups(data.allAirtable.group, "items").map((e, index) => (
            <div
              key={index}
              css={css`
                height: 3.8rem;
                max-width: 6.8rem;
                display: inline-block;
                margin: 0 20px;
                vertical-align: middle;
                padding: 12px 14px;
                svg {
                  height: 100%;
                  max-width: 100%;
                  fill: ${color.main_dark};
                }
              `}
            >
              <PartnerLogo id={e.node.recordId} />
            </div>
          ))}
        </Marquee>
      </div>

      {/* <div css={[wrapper, css`
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 2rem;
        padding-top: 5rem;
        padding-bottom: 5em;
        ${mq[2]} {
          padding-bottom: 0;
        }
      `]}>
          <p css={css`
            color: white;
            font-size: .75em;
            font-weight: 600;
            letter-spacing: 0.03em;
          `}>Crafted with &#9829; in Stuttgart.</p>
        </div> */}
    </section>
  )
}

export default PartnerMarquee
