import React from "react"
import { css } from "@emotion/react"
import Link from "../link"
import { color, fontStyle, spacing } from "../defaults/styles/theme"
import { wrapper, IconButton } from "../defaults/styles/elements"
import mq from "../defaults/styles/mediaquerys"
import VCMagazine from "../defaults/assets/logos/mediapartners/venturecapitalmagazine.svg"
import MotorPresseStuttgart from "../defaults/assets/logos/mediapartners/motorpressestuttgart.svg"
import StartupBW from "../defaults/assets/logos/mediapartners/startupbw.svg"
import Startupinsider from "../defaults/assets/logos/mediapartners/startupinsider.svg"
import Arena2036 from "../defaults/assets/logos/mediapartners/arena2036.svg"
import TheLaend from "../defaults/assets/logos/mediapartners/thelaend.svg"
import Automobilwoche from "../defaults/assets/logos/mediapartners/automobilwoche.svg"
import UniEni from "../defaults/assets/logos/mediapartners/unieni.svg"
import StartupValley from "../defaults/assets/logos/mediapartners/startupvalley.svg"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay } from "swiper"
import "swiper/css"
import "swiper/css/autoplay"

const SponsorItems = [
  {
    icon: <MotorPresseStuttgart />,
    name: "Motor Presse Stuttgart",
    url: "https://www.motorpresse.de/",
  },
  {
    icon: <Automobilwoche />,
    name: "Automobilwoche",
    url: "https://www.automobilwoche.de/",
  },
  {
    icon: <Startupinsider />,
    name: "Startup Insider",
    url: "https://www.startup-insider.com/",
  },
  {
    icon: <StartupValley />,
    name: "Startup Valley",
    url: "https://startupvalley.news/de",
  },
  {
    icon: <VCMagazine />,
    name: "VentureCapital Magazine",
    url: "https://www.vc-magazin.de/",
  },
  {
    icon: <StartupBW />,
    name: "Startup BW",
    url: "https://www.startupbw.de/",
  },
  // {
  //   icon: <DriveTLV />,
  //   name: "Drive TLV",
  //   url: "https://www.drivetlv.com/",
  // },
  {
    icon: <Arena2036 />,
    name: "ARENA2036",
    url: "https://www.arena2036.de/en/",
  },
  // {
  //   icon: <TheLaend />,
  //   name: "THE LÄND",
  //   url: "https://www.thelaend.de/",
  // },
  // {
  //   icon: <WomanAutomotiveNetwork />,
  //   name: "Women Automotive Network",
  //   url: "https://womenautomotivenetwork.com/en-de",
  // },
  {
    icon: <UniEni />,
    name: "Institute of Entrepreneurship and Innovation Science (ENI)",
    url: "https://www.eni.uni-stuttgart.de/en/",
  },
]

const Speaker = ({ theme }) => {
  return (
    <section
      css={[
        spacing.paddingSection,
        css`
          background: ${theme ? theme : color.richBlack};
        `,
      ]}
    >
      <div
        css={[
          wrapper,
          css`
            position: relative;
            z-index: 1;
          `,
        ]}
      >
        <h2 css={fontStyle.header.landingSection}>Media Partners</h2>
        <div
          css={css`
            p {
              ${fontStyle.copy.landing}
            }
          `}
        >
          <p>
            We're proud to introduce the media outlets and network partners that
            have chosen to support our event.
          </p>
        </div>
        <Swiper
          css={[
            css`
              margin-top: 5em;
            `,
          ]}
          spaceBetween={150}
          slidesPerView={1}
          modules={[Autoplay]}
          autoplay={{ delay: 5000 }}
          breakpoints={{
            576: { slidesPerView: 2 },
            992: { slidesPerView: 3 },
            1200: { slidesPerView: 3 },
          }}
        >
          {SponsorItems.map((el, index) => (
            <SwiperSlide key={index} style={{ height: "unset" }}>
              <div
                css={[
                  css`
                    /* shadow fix */
                    height: 100%;
                  `,
                ]}
              >
                <Link
                  css={[
                    css`
                      display: flex;
                      height: 100%;
                    `,
                  ]}
                  to={el.url}
                >
                  {el.icon}
                </Link>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  )
}

export default Speaker
