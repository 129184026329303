import React from "react"
import { css } from "@emotion/react"
import { StaticImage } from "gatsby-plugin-image"
import { fontStyle, color } from "../defaults/styles/theme"
import { IconButton } from "../defaults/styles/elements"
import mq from "../defaults/styles/mediaquerys"
import { wrapper } from "../defaults/styles/elements"
import { spacing } from "../defaults/styles/theme"

const landingButton = css`
  font-size: 1em;
  padding: 0.5em 1.5em 0.65em;
  font-family: "ESKlarheitKurrent";
  color: ${color.white};
  font-weight: 600;
  display: inline-block;
  &:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${color.main_dark};
    border-radius: 0;
  }
`

const Floorplan = ({ theme }) => {
  return (
    <section
      css={[
        spacing.paddingSection,
        css`
          background: ${theme};
        `,
      ]}
    >
      <div
        css={[
          css`
            background: ${color.e24green};
            position: relative;
          `,
        ]}
      >
        <div
          css={[
            wrapper,
            css`
              display: flex;
            `,
          ]}
        >
          <div
            css={[
              css`
                position: absolute;
                top: 0;
                left: -20%;
                height: 100%;
              `,
            ]}
          >
            <StaticImage
              src="../../../static/img/expo2024floorplan.png"
              alt="Official Floorplan of EXPO2024"
              quality="100"
              placeholder="tracedSVG"
              css={css`
                height: 100%;
                width: auto;
                /* min-width: 110%; */
                ${mq[1]} {
                  /* min-width: 700px; */
                  /* max-width: 700px; */
                }
              `}
            />
          </div>
          <div
            css={[
              css`
                position: relative;
                margin-left: 0;
                margin-top: 5em;
                margin-bottom: 15em;
                ${mq[2]} {
                  margin-left: 40%;
                }
              `,
            ]}
          >
            <h2 css={fontStyle.header.landingSection}>
              Navigate through EXPO2024 with the floorpan
            </h2>
            <div
              css={[
                css`
                  p {
                    ${fontStyle.copy.landing}
                  }
                `,
              ]}
            >
              <p>
                Stages, project booths, show cars, lounge zones, exits, — we
                mapped it all on our floorpan. Save it on your phone to find
                your way easier.
              </p>
              <IconButton
                url="/media/expo2024floorplan.pdf"
                css={landingButton}
                name="Access Floorplan"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Floorplan
