import React from "react"
import { css } from "@emotion/react"
import { spacing, color, fontStyle } from "../defaults/styles/theme"
import { wrapper } from "../defaults/styles/elements"
import { StaticImage } from "gatsby-plugin-image"
import Flags from "../../data/raw/flags"
import { IconButton } from "../defaults/styles/elements"
import Link from "../link"

const Speakerseries = ({ theme }) => {
  return (
    <section
      css={[
        spacing.paddingSection,
        css`
          background: ${theme ? theme : color.richBlack};
        `,
      ]}
    >
      <div
        css={[
          wrapper,
          css`
            position: relative;
            z-index: 1;
          `,
        ]}
      >
        <h2 css={fontStyle.header.landingSection}>Speaker Series</h2>
        <div
          css={css`
            p {
              ${fontStyle.copy.landing}
            }
          `}
        >
          <p>
            On Friday, June 23, we’re hosting the Speaker Series as an EXPO2023
            side event — together with our founding partner University of
            Stuttgart. The speaker lineup features Utkarsh Mishra from our
            ecosystem partners Hero MotoCorp.
          </p>
        </div>
        {Flags.features.people && (
          <IconButton
            name="Speaker Series Registration"
            to="https://eveeno.com/338380814"
            css={[
              css`
                font-size: 1em;
                padding: 0 0.2em 0.3em;
                font-family: PPNeueMachina;
                color: ${color.main_dark};
                font-weight: 600;
                margin-bottom: 3em;
                display: inline-block;
                &:before {
                  top: 100%;
                  left: 0;
                  width: 100%;
                  height: 3px;
                  background: ${color.e23violette};
                }
              `,
            ]}
          />
        )}
        <Link to="https://eveeno.com/338380814">
          <StaticImage
            src="../../../static/img/speakerseries.jpg"
            alt="Speaker Series Poster"
            quality="90"
            placeholder="blur"
          />
        </Link>
      </div>
    </section>
  )
}

export default Speakerseries
