import React from "react"
import { wrapper } from "../defaults/styles/elements"
import { css } from "@emotion/react"
import { spacing } from "../defaults/styles/theme"
import Signature from "../defaults/assets/svg/signature.svg"

const Introduction = ({ theme }) => {
  return (
    <section
      css={[
        spacing.paddingSection,
        css`
          background: ${theme};
        `,
      ]}
    >
      <div
        css={[
          wrapper,
          css`
            text-align: center;
            position: relative;
            z-index: 1;
          `,
        ]}
      >
        <p
          css={[
            css`
              font-family: "ESKlarheitKurrent";
              font-size: 3em;
            `,
          ]}
        >
          Innovate.{" "}
          <span
            css={[
              css`
                font-family: "ESKlarheitPlakat";
              `,
            ]}
          >
            Inspire.
          </span>{" "}
          Change.
        </p>
        <div
          css={[
            css`
              max-width: 750px;
              margin: 0 auto;
            `,
          ]}
        >
          <p>
            Our flagship EXPO event returns on June 6, 2024, for its 13th
            edition, this time in the brand-new venue — Im Wizemann, one of
            Stuttgart’s top event locations. The one-day event is packed with
            keynotes and panel discussions from the leading voices of the
            automotive industry, project showcases, and unrivaled networking
            opportunities.
          </p>
          <p>
            Join us and connect with an exclusively selected audience of 1,000+
            guests: board members and C-Level executives from leading automotive
            companies, entrepreneurs, investors, political decision-makers, and
            tech enthusiasts.
          </p>
          <p>
            Discover how breakthrough ideas come to life, experience tangible
            collaboration results, and inspire change with us on June 6, 2024.
          </p>
        </div>
        <div
          css={[
            css`
              height: 3em;
              max-width: 100%;
            `,
          ]}
        >
          <Signature
            css={[
              css`
                height: 100%;
                width: 100%;
              `,
            ]}
          />
        </div>
      </div>
    </section>
  )
}

export default Introduction
