import React from "react"
import { css } from "@emotion/react"
import { wrapper } from "../defaults/styles/elements"
import mq from "../defaults/styles/mediaquerys"

const LandingLoop = ({ windowHeight }) => {
  return (
    <section
      css={css`
        width: 100%;
        height: ${windowHeight ? windowHeight + "px" : "100vh"};
        position: relative;
      `}
    >
      <video
        autoPlay
        playsInline
        muted
        loop
        poster="../img/expo2023-placeholder.jpg"
        css={[
          css`
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: bottom;
          `,
        ]}
      >
        <source src="../media/expo2023-landing_loop.mp4" type="video/mp4" />
      </video>
      <div
        css={[
          css`
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
          `,
        ]}
      >
        <div
          css={[
            wrapper,
            css`
              width: 100%;
              margin-top: 120px;
            `,
          ]}
        >
          <div
            css={[
              css`
                h1,
                p {
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  max-width: 100%;
                  overflow: hidden;
                  line-height: 1.2em;
                }
              `,
            ]}
          >
            <h1
              css={[
                css`
                  font-family: "PPNeueMachina";
                  font-size: clamp(2.5rem, 13vw, 6.25em);
                  font-weight: 300;
                  margin-bottom: 0;
                  margin-left: -0.05em;
                `,
              ]}
            >
              EXPO2023
            </h1>
            <p
              css={[
                css`
                  font-size: clamp(1rem, 9vw, 2.25em);
                `,
              ]}
            >
              Piloting the Future
            </p>
          </div>
          <div
            css={[
              css`
                max-width: 650px;
                width: 100%;
                margin-top: 3em;
                ${mq[1]} {
                  float: right;
                }
                p {
                  font-size: 1em;
                  text-align: left;
                  /* line-height: 1.23em; */
                  line-height: 1.43em;
                  font-weight: 400;
                  ${mq[1]} {
                    text-align: right;
                    font-size: 1.2em;
                  }
                }
              `,
            ]}
          >
            <p>
              STARTUP AUTOBAHN powered by Plug and Play is the ultimate
              innovation platform that unites the unrivaled tech expertise of
              Silicon Valley and the best of German engineering. Just like the
              Autobahn, we accelerate connections between industry corporations
              and innovative startups. The outcome of this collaboration is a
              showcase of their joint projects at our annual EXPO Day.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LandingLoop
