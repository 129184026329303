import React from "react"
import { css } from "@emotion/react"
import { StaticImage } from "gatsby-plugin-image"
import { color, fontStyle, spacing } from "../defaults/styles/theme"
import { wrapper, IconButton } from "../defaults/styles/elements"
import mq from "../defaults/styles/mediaquerys"
import Flags from "../../data/raw/flags"
//import Video from "../defaults/assets/svg/video.svg"

const landingButton2 = css`
  font-size: 1em;
  padding: 0 0.2em 0.3em;
  font-family: PPNeueMachina;
  color: ${color.main_dark};
  font-weight: 600;
  &:before {
    top: 100%;
    left: 0;
    width: 100%;
    height: 3px;
    background: ${color.e24green};
  }
`
const landingButton = css`
  font-size: 1em;
  padding: 0.5em 1.5em 0.65em;
  font-family: "ESKlarheitKurrent";
  color: ${color.white};
  font-weight: 600;
  display: inline-block;
  &:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${color.main_dark};
    border-radius: 0;
  }
`

const Speaker = ({ theme }) => {
  return (
    <section
      css={[
        spacing.paddingSection,
        css`
          background: ${theme ? theme : color.richBlack};
        `,
      ]}
    >
      <div
        css={[
          wrapper,
          css`
            margin-bottom: 1em;
            position: relative;
            z-index: 1;
          `,
        ]}
      >
        <h2 css={fontStyle.header.landingSection}>Our speakers at EXPO2024</h2>
        <div
          css={css`
            p {
              ${fontStyle.copy.landing};
              max-width: 600px;
            }
          `}
        >
          <p>
            We're excited to announce our impressive lineup of speakers for this
            year's EXPO. Check out a sample below, or visit the Speakers page
            for the full list (soon available).
          </p>
        </div>
        {Flags.features.people && (
          <IconButton
            name="Speakers of EXPO2024"
            to="/people/all"
            css={[landingButton]}
          />
        )}
      </div>
      <div
        css={css`
          display: flex;
          justify-content: center;
          overflow: hidden;
          margin-top: -4em;
          ${mq[2]} {
            margin-top: ${Flags.features.Speaker ? "-8em" : "-4em"};
          }
        `}
      >
        <StaticImage
          src="../../../static/img/expo2024speakers.png"
          alt="EXPO2024 speaker of STARTUP AUTOBAHN powered by Plug and Play"
          quality="91"
          placeholder="tracedSVG"
          css={css`
            min-width: 1600px;
          `}
        />
      </div>
    </section>
  )
}

export default Speaker
