import React from "react"
import { css } from "@emotion/react"
import { color, fontStyle } from "../defaults/styles/theme"
import { useStaticQuery, graphql } from "gatsby"
import mq from "../defaults/styles/mediaquerys"
import Backdrop from "../defaults/assets/svg/e24backdrop-center.svg"
import BackdropMobile from "../defaults/assets/svg/e24backdrop-mobile.svg"
import { wrapper } from "../defaults/styles/elements"

const Landing = ({ windowHeight }) => {
  return (
    <section
      css={[
        css`
          width: 100%;
          height: ${windowHeight ? windowHeight + "px" : "100vh"};
          background: #fafafa;
          overflow-x: clip;
          position: relative;
          ${mq[1]} {
            min-height: 850px;
          }
        `,
      ]}
    >
      <div
        css={[
          wrapper,
          css`
            display: flex;
            flex-direction: column;
            height: 100%;
          `,
        ]}
      >
        <div
          css={[
            css`
              width: 100%;
              height: 25%;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 2;
              backdrop-filter: blur(5px);
              -webkit-mask: linear-gradient(0deg, transparent, black 50%);
              background: linear-gradient(
                to bottom,
                hsl(0, 0%, 100%) 0%,
                hsla(0, 0%, 100%, 0.987) 8.1%,
                hsla(0, 0%, 100%, 0.951) 15.5%,
                hsla(0, 0%, 100%, 0.896) 22.5%,
                hsla(0, 0%, 100%, 0.825) 29%,
                hsla(0, 0%, 100%, 0.741) 35.3%,
                hsla(0, 0%, 100%, 0.648) 41.2%,
                hsla(0, 0%, 100%, 0.55) 47.1%,
                hsla(0, 0%, 100%, 0.45) 52.9%,
                hsla(0, 0%, 100%, 0.352) 58.8%,
                hsla(0, 0%, 100%, 0.259) 64.7%,
                hsla(0, 0%, 100%, 0.175) 71%,
                hsla(0, 0%, 100%, 0.104) 77.5%,
                hsla(0, 0%, 100%, 0.049) 84.5%,
                hsla(0, 0%, 100%, 0.013) 91.9%,
                hsla(0, 0%, 100%, 0) 100%
              );
            `,
          ]}
        ></div>
        <div
          css={[
            css`
              display: block;
              position: relative;
              flex: 0 1 auto;
              height: 100%;
              svg {
                min-height: 100%;
                min-width: 200%;
                position: absolute;
                bottom: 0px;
              }
              ${mq[1]} {
                display: none;
              }
            `,
          ]}
        >
          <BackdropMobile />
        </div>
        <div
          css={[
            css`
              display: none;
              position: absolute;
              width: 100%;
              top: 45.5%;
              transform: translateY(-50%);
              min-width: 1500px;
              max-width: 3000px;
              z-index: 1;
              ${mq[1]} {
                display: block;
              }
            `,
          ]}
        >
          <Backdrop />
        </div>
        <div
          css={[
            css`
              position: relative;
              font-family: "ESKlarheitKurrent";
              line-height: 1.4em;
              font-size: 0.9em;
              max-width: 35em;
              flex: 0 0 auto;
              margin-bottom: 60px;
              z-index: 1;
              ${mq[1]} {
                font-size: 1em;
                line-height: 1.5em;
                bottom: 4em;
                margin-bottom: unset;
                position: absolute;
              }
            `,
          ]}
        >
          <p>
            Since 2016, STARTUP AUTOBAHN powered by Plug and Play has been the
            ultimate matchmaker for startups and corporate partners from the
            automotive industry that co-develop innovative solutions in
            mobility, production, enterprise, sustainability, and beyond. We
            invite you to experience their collaboration results at EXPO2024.
          </p>
        </div>
      </div>
    </section>
  )
}

export default Landing
